import * as React from "react";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle";
import "typeface-roboto";
import "typeface-roboto-slab";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import MyHeader from "components/Header/MyHeader";
import MyFooter from "components/Footer/MyFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";

// Sections for this page
import SectionFeatures from "./TracePage/Sections/SectionFeatures";
import SectionTestimonials from "./HomePage/Sections/SectionTestimonials";

const useStyles = makeStyles(landingPageStyle);

// markup
const TracePage = ({ ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <MyHeader {...rest} />
      <Parallax
        image={require("assets/img/trace-widescreen2.png")}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer alignItems="flex-end" justifyContent="space-between">
            <GridItem xs={6}>
              <h1 className={classes.title}>TRACE</h1>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer alignItems="flex-end">
                <GridItem xs={7}>
                  <h4>
                    All your creative elements available to you and your entire
                    team at any time on any device. TRACE your elements.
                  </h4>
                  <div style={{ textAlign: "end" }}>
                    <Button color="danger" size="lg" href="/">
                      <i className="fas fa-play" />
                      Book Your Demo
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <SectionFeatures />
        </div>
        <SectionTestimonials />
      </div>
      <MyFooter {...rest} />
    </div>
  );
};

export default TracePage;
